import { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import Axios from "axios";
import "./App.css";

function App() {
  const [author, setAuthor] = useState("Simon");
  const [blogEntry, setBlogEntry] = useState("");
  const url = "https://blog-editor-api.herokuapp.com/";
  const saveEntry = async () => {
    await Axios.post(url, {
      body: blogEntry,
      author: author,
    });
    window.location.reload();
  };

  return (
    <div className="App">
      <div>
        <label>Author: </label>
        <input value={author} onChange={(e) => setAuthor(e.target.value)} />
      </div>
      <Editor
        apiKey="6ilzbaplw8aiz22ses3v4ak4e6c2wdya51qvacs4i40not1y"
        initialValue=""
        onEditorChange={setBlogEntry}
        init={{
          height: 400,
          menubar: true,
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | " +
            "bold italic backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",
          content_style:
            "@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');body { font-family:'Poppins',Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
      <button onClick={saveEntry}>Save</button>
    </div>
  );
}

export default App;
